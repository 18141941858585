import {inject, NgModule} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@NgModule({
  imports: [],
  declarations: [],
  providers: [],
  exports: [],
  bootstrap: []
})
export class MsaIconModule {
  private matIconRegistry: MatIconRegistry = inject(MatIconRegistry);
  private domSanitizer: DomSanitizer = inject(DomSanitizer);
  private trustUrl = this.domSanitizer.bypassSecurityTrustResourceUrl;

  constructor() {
    // social media icons
    this.matIconRegistry.addSvgIconInNamespace(
      'logo',
      'facebook',
      this.trustUrl('assets/images/socialmedia_facebook.svg')
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'logo',
      'instagram',
      this.trustUrl('assets/images/socialmedia_instagram.svg')
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'logo',
      'youtube',
      this.trustUrl('assets/images/socialmedia_youtube.svg')
    );
    this.matIconRegistry.addSvgIconInNamespace(
      'logo',
      'linkedin',
      this.trustUrl('assets/images/socialmedia_linkedin.svg')
    );
    this.matIconRegistry.addSvgIconInNamespace('logo', 'tiktok', this.trustUrl('assets/images/socialmedia_tiktok.svg'));

    // fed icons
    this.matIconRegistry.addSvgIconInNamespace('logo', 'fed', this.trustUrl('assets/images/fed-logo.svg'));

    // ui icons from figma
    this.matIconRegistry.addSvgIcon('arrow-east', this.trustUrl('assets/icons/dn_icon/arrow_east.svg'));
    this.matIconRegistry.addSvgIcon('portal', this.trustUrl('assets/icons/dn_icon/portal.svg'));
    this.matIconRegistry.addSvgIcon('mail', this.trustUrl('assets/icons/dn_icon/mail.svg'));
    this.matIconRegistry.addSvgIcon('sms', this.trustUrl('assets/icons/dn_icon/sms.svg'));
    this.matIconRegistry.addSvgIcon('circle_check', this.trustUrl('assets/icons/dn_icon/circle_check.svg'));
    this.matIconRegistry.addSvgIcon('circle_info', this.trustUrl('assets/icons/dn_icon/circle_info.svg'));
    this.matIconRegistry.addSvgIcon('circle_warning', this.trustUrl('assets/icons/dn_icon/circle_warning.svg'));
    this.matIconRegistry.addSvgIcon('circle_not_ok', this.trustUrl('assets/icons/dn_icon/circle_not_ok.svg'));
    this.matIconRegistry.addSvgIcon('close', this.trustUrl('assets/icons/dn_icon/close.svg'));
    this.matIconRegistry.addSvgIcon('bookmark', this.trustUrl('assets/icons/dn_icon/bookmark.svg'));
    this.matIconRegistry.addSvgIcon('check', this.trustUrl('assets/icons/dn_icon/check.svg'));
    this.matIconRegistry.addSvgIcon('circle_1', this.trustUrl('assets/icons/dn_icon/circle_1.svg'));
    this.matIconRegistry.addSvgIcon('mobile', this.trustUrl('assets/icons/dn_icon/mobile.svg'));
    this.matIconRegistry.addSvgIcon('telefon', this.trustUrl('assets/icons/dn_icon/telefon.svg'));
    this.matIconRegistry.addSvgIcon('calendar', this.trustUrl('assets/icons/dn_icon/calendar.svg'));
    this.matIconRegistry.addSvgIcon('clock', this.trustUrl('assets/icons/dn_icon/clock.svg'));
    this.matIconRegistry.addSvgIcon('delete', this.trustUrl('assets/icons/dn_icon/delete.svg'));
    this.matIconRegistry.addSvgIcon('plus', this.trustUrl('assets/icons/dn_icon/plus.svg'));

    // language dependent icons
    this.matIconRegistry.addSvgIconInNamespace('logo', 'armee', this.trustUrl('assets/images/logo_de_white.svg'));
    this.matIconRegistry.addSvgIconInNamespace('de-logo', 'armee', this.trustUrl('assets/images/logo_de_white.svg'));
    this.matIconRegistry.addSvgIconInNamespace('fr-logo', 'armee', this.trustUrl('assets/images/logo_fr_white.svg'));
    this.matIconRegistry.addSvgIconInNamespace('it-logo', 'armee', this.trustUrl('assets/images/logo_it_white.svg'));

    // common icons
    this.matIconRegistry.addSvgIcon('search', this.trustUrl('assets/images/icon-search.svg'));
    this.matIconRegistry.addSvgIcon('search-left', this.trustUrl('assets/images/icon-search-left.svg'));
    this.matIconRegistry.addSvgIcon('calendar-add', this.trustUrl('assets/images/icon-calendar-add.svg'));
    this.matIconRegistry.addSvgIcon('bell', this.trustUrl('assets/images/icon-bell.svg'));
    this.matIconRegistry.addSvgIcon('user', this.trustUrl('assets/images/icon-user.svg'));
    this.matIconRegistry.addSvgIcon('logout', this.trustUrl('assets/images/icon-logout.svg'));
    this.matIconRegistry.addSvgIcon('cloud', this.trustUrl('assets/images/icon-cloud.svg'));
    this.matIconRegistry.addSvgIcon('close', this.trustUrl('assets/images/icon-close.svg'));

    // avatar and troop icons
    this.matIconRegistry.addSvgIcon('avatar_male', this.trustUrl('assets/images/avatar_male.svg'));
    this.matIconRegistry.addSvgIcon('avatar_female', this.trustUrl('assets/images/avatar_female.svg'));
    this.matIconRegistry.addSvgIcon('military-function', this.trustUrl('assets/images/military-function.svg'));
    this.matIconRegistry.addSvgIcon('troops', this.trustUrl('assets/images/troops.svg'));
    this.matIconRegistry.addSvgIcon('commandSupportForces', this.trustUrl('assets/images/commandSupportForces.svg'));

    // avatar
    this.matIconRegistry.addSvgIcon('ada_f', this.trustUrl('assets/icons/dn_picto/avatar/ada_f.svg'));
    this.matIconRegistry.addSvgIcon('ada_m', this.trustUrl('assets/icons/dn_picto/avatar/ada_m.svg'));
    this.matIconRegistry.addSvgIcon('alumni', this.trustUrl('assets/icons/dn_picto/avatar/alumni.svg'));
    this.matIconRegistry.addSvgIcon('private', this.trustUrl('assets/icons/dn_picto/avatar/private.svg'));

    // troops
    this.matIconRegistry.addSvgIcon('abc', this.trustUrl('assets/icons/dn_picto/abz/truppen/abc.svg'));
    this.matIconRegistry.addSvgIcon('art', this.trustUrl('assets/icons/dn_picto/abz/truppen/art.svg'));
    this.matIconRegistry.addSvgIcon('flab', this.trustUrl('assets/icons/dn_picto/abz/truppen/flab.svg'));
    this.matIconRegistry.addSvgIcon('genie', this.trustUrl('assets/icons/dn_picto/abz/truppen/genie.svg'));
    this.matIconRegistry.addSvgIcon('inf', this.trustUrl('assets/icons/dn_picto/abz/truppen/inf.svg'));
    this.matIconRegistry.addSvgIcon('log', this.trustUrl('assets/icons/dn_picto/abz/truppen/log.svg'));
    this.matIconRegistry.addSvgIcon('lw', this.trustUrl('assets/icons/dn_picto/abz/truppen/lw.svg'));
    this.matIconRegistry.addSvgIcon('mp', this.trustUrl('assets/icons/dn_picto/abz/truppen/mp.svg'));
    this.matIconRegistry.addSvgIcon('pz', this.trustUrl('assets/icons/dn_picto/abz/truppen/pz.svg'));
    this.matIconRegistry.addSvgIcon('rttg', this.trustUrl('assets/icons/dn_picto/abz/truppen/rttg.svg'));
    this.matIconRegistry.addSvgIcon('san', this.trustUrl('assets/icons/dn_picto/abz/truppen/san.svg'));
    this.matIconRegistry.addSvgIcon('sk', this.trustUrl('assets/icons/dn_picto/abz/truppen/sk.svg'));
    this.matIconRegistry.addSvgIcon('uem', this.trustUrl('assets/icons/dn_picto/abz/truppen/uem.svg'));

    // grad
    this.matIconRegistry.addSvgIcon('sdt', this.trustUrl('assets/icons/dn_picto/grad/sdt.svg'));
    this.matIconRegistry.addSvgIcon('adjuof', this.trustUrl('assets/icons/dn_picto/grad/adjuof.svg'));
    this.matIconRegistry.addSvgIcon('br', this.trustUrl('assets/icons/dn_picto/grad/br.svg'));
    this.matIconRegistry.addSvgIcon('chefadj', this.trustUrl('assets/icons/dn_picto/grad/chefadj.svg'));
    this.matIconRegistry.addSvgIcon('div', this.trustUrl('assets/icons/dn_picto/grad/div.svg'));
    this.matIconRegistry.addSvgIcon('fachof', this.trustUrl('assets/icons/dn_picto/grad/fachof.svg'));
    this.matIconRegistry.addSvgIcon('four', this.trustUrl('assets/icons/dn_picto/grad/four.svg'));
    this.matIconRegistry.addSvgIcon('fw', this.trustUrl('assets/icons/dn_picto/grad/fw.svg'));
    this.matIconRegistry.addSvgIcon('general', this.trustUrl('assets/icons/dn_picto/grad/general.svg'));
    this.matIconRegistry.addSvgIcon('gfr', this.trustUrl('assets/icons/dn_picto/grad/gfr.svg'));
    this.matIconRegistry.addSvgIcon('hauptadj', this.trustUrl('assets/icons/dn_picto/grad/hauptadj.svg'));
    this.matIconRegistry.addSvgIcon('hptfw', this.trustUrl('assets/icons/dn_picto/grad/hptfw.svg'));
    this.matIconRegistry.addSvgIcon('hptm', this.trustUrl('assets/icons/dn_picto/grad/hptm.svg'));
    this.matIconRegistry.addSvgIcon('kkdt', this.trustUrl('assets/icons/dn_picto/grad/kkdt.svg'));
    this.matIconRegistry.addSvgIcon('kpl', this.trustUrl('assets/icons/dn_picto/grad/kpl.svg'));
    this.matIconRegistry.addSvgIcon('lt', this.trustUrl('assets/icons/dn_picto/grad/lt.svg'));
    this.matIconRegistry.addSvgIcon('maj', this.trustUrl('assets/icons/dn_picto/grad/maj.svg'));
    this.matIconRegistry.addSvgIcon('oberst', this.trustUrl('assets/icons/dn_picto/grad/oberst.svg'));
    this.matIconRegistry.addSvgIcon('oberstlt', this.trustUrl('assets/icons/dn_picto/grad/oberstlt.svg'));
    this.matIconRegistry.addSvgIcon('obgfr', this.trustUrl('assets/icons/dn_picto/grad/obgfr.svg'));
    this.matIconRegistry.addSvgIcon('oblt', this.trustUrl('assets/icons/dn_picto/grad/oblt.svg'));
    this.matIconRegistry.addSvgIcon('obwm', this.trustUrl('assets/icons/dn_picto/grad/obwm.svg'));
    this.matIconRegistry.addSvgIcon('stabsadj', this.trustUrl('assets/icons/dn_picto/grad/stabsadj.svg'));
    this.matIconRegistry.addSvgIcon('wm', this.trustUrl('assets/icons/dn_picto/grad/wm.svg'));
  }
}
